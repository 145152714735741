import React, {useEffect, useState} from 'react'
import logo from '../img/logo-glocal.png'

import { useTranslation } from 'react-i18next';

const Header = () => {
    
    const { i18n } = useTranslation();

    const setLanguage = (value) => {
        i18n.changeLanguage(value);
    }

    useEffect(() => {
        const queryString = window.location.search;
        console.log(queryString);
        
        const urlParams = new URLSearchParams(queryString);

        const lan =  urlParams.get('lang');
        console.log(lan);
        setLanguage(lan);
    }, []);

    const style = "block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 text-right"

    return (
        <nav className="fixed w-full flex items-center justify-between flex-wrap darkGrey p-0 border-solid border-b-8">
            <div className="container mx-auto flex">
                <div className="flex items-center flex-shrink-0 text-white mx-auto">
                   <img src={logo} alt="logo" className="logo ml-8 md:ml-0" />
                </div>
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto text-right">
                    <div className="text-sm lg:flex-grow text-right mr-8 md:mr-0">
                        <span className={style} onClick={(e) => setLanguage("it")} className="text-white hover:text-white link:text-white">ITA</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={style} onClick={(e) => setLanguage("en")} className="text-white hover:text-white link:text-white">ENG</span>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;