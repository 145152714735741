import React from 'react';

import { useTranslation } from 'react-i18next';

import '@fortawesome/fontawesome-free/css/all.css';
import './styles/main.css';
import './App.css';

import Header from './components/Header'
import Intro from './components/Intro'
import Description from './components/Description'
import Spazio from './components/Spazio'
import Service from './components/Service'
import Contact from './components/Contact'
import Footer from './components/Footer'

function App() {
  
  const { t } = useTranslation();

  const listIcon = ["fas fa-laptop-code", "fas fa-microphone", "fas fa-video", "fas fa-industry", "fas fa-sitemap", "far fa-comments", "far fa-file-pdf"]

  const listService = [
    {icon: "fas fa-industry", title: t('azienda'), text: t('azienda_desc')},
    {icon: "fab fa-unity", title: t('spazios'), text: t('spazio_desc')},
    {icon: "fas fa-video", title: t('video'), text: t('video_desc')},
    {icon: "fas fa-microphone", title: t('audio'), text: t('audio_desc')},
    {icon: "fas fa-sitemap", title: t('diretta'), text: t('diretta_desc')},
    {icon: "far fa-comments", title: t('interazione'), text: t('interazione_desc')}, 
    {icon: "far fa-file-pdf", title: t('doc'), text: t('doc_desc')},
    {icon: "fas fa-laptop-code", title: t('offline'), text: t('offline_desc')},    
  ]

  const banner = (
    <div className="w-full bgBlue mt-16 py-16">
      <p className="text-white text-xl md:text-6xl text-center">
        {t('banner')}
      </p>
      <p className="text-white text-6xl text-center"><i class="fas fa-film"></i></p>
    </div>
  )


  const service = (
    <div className="box p-4 md:p-12 borderBlue border-4 rounded-md">
      {listService.map((item, index) => (
          <Service title={item.title} text={item.text} icon={item.icon} key={index} />
      ))}
    </div>
  )

  return (
    <div className="App">
      <Header />

      <div className="container mx-auto pt-32 px-8 md:px-0">
        <Intro text={t('intro')} />
      </div>

      {banner}

      <div className="container mx-auto pt-12 px-8 md:px-0">
        <div className="w-full center pb-8">
            <iframe 
                className="video-centro"
                src="https://www.youtube.com/embed/oLVwR6JacUc?theme=dark&autohide=2&modestbranding=1" 
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
        </div>

        <Description text={t('descrizione')} icons={listIcon} />
      </div>

      <div className="container mx-auto px-8 md:px-0">
        <Spazio text={t('spazio')} />
      </div>

      <div className="container mx-auto py-32 px-4 md:px-0">
        {service}
      </div>

      <Contact text={t('contactText')} />

      <Footer />

    </div>
  );
}

export default App;
