import React from 'react'
import spazio from '../img/spazio.png'

function Spazio({text}) {

    return (
        <div className="object-center">
            <img src={spazio} alt="spazio olografico" className="object-center center-img" />
            <p className="mt-12 uppercase text-xl textGrey text-center"
                dangerouslySetInnerHTML={{__html: text }}>

            </p>
        </div>
    )
}


export default Spazio;