import React from 'react'



function Intro({text}) {

    return (
        <div>
            <p className="textBlue text-6xl text-center">
                <i className="fas fa-globe"></i>
            </p>
            <p className="uppercase textGrey text-lg text-center"
                dangerouslySetInnerHTML={{__html: text }}></p>
        </div>

    )

}

export default Intro;