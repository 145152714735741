import React from 'react'

import logo from '../img/logo-glocal.png'


function Footer() {

    return (
        <div className="w-full darkGrey py-12  objet-center">
            <img src={logo} alt="logo" className="logo logo-footer"/>

            <p className="text-center text-base text-white font-bold">
                Glocal srl
            </p>
            <p className="text-center text-base text-white">
                Via Roma 133/D-G - 24064 Grumello del Monte (BG) - glocalnow@gmail.com
            </p>
            <p className="text-center text-base text-white">
                All Right reserved Glocal srl - C.F. e P.IVA: 03971490168
            </p>
        </div>
    )
}

export default Footer;