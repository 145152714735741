import React, {useState} from 'react'


function Contact({text}) {

    const inputStyle = "appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
    
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();

    const handleName = (event) => { setName(event.target.value) }
    const handlePhone = (event) => { setPhone(event.target.value) }
    const handleEmail = (event) => { setEmail(event.target.value) }

    const mailgun = require("mailgun-js");
    const DOMAIN = "sandboxdc443ad16ed84473bfe08833c4332107.mailgun.org";
    const sendEmail = () => {
        if (email) {
            const mg = mailgun({apiKey: "c71198e6816d72cad88fb76bd77256b8-cb3791c4-d0640921", domain: DOMAIN});
            const data = {
                from: "Mailgun Sandbox <postmaster@sandboxdc443ad16ed84473bfe08833c4332107.mailgun.org>",
                to: "glocalnow@gmail.com",
                subject: "Email Inviata da VIDEO Glocal",
                text: `nome: ${name} \n Phone: ${phone} \n ${email}`
            };
            mg.messages().send(data, function (error, body) {
                console.log(body);
                alert("Email inviata con successo, ti contatteremo al più presto!");
            });
        } else {
            alert("Email non iserità");
        }
    }

    return (

        <div className="contact mx-auto mb-32">
            <div className="box borderBlue lightGrey rounded-lg p-8">
                <p className="textBlue text-2xl mb-3">{ text }</p>

                <input type="text" 
                        className={inputStyle}
                        name="name"
                        value={name}
                        onChange={handleName}
                        placeholder="Nome/Name"/>
                
                <input type="text" 
                        className={inputStyle} 
                        name="phone"
                        value={phone}
                        onChange={handlePhone}
                        placeholder="Tel"/>
                
                <input type="text" 
                        className={inputStyle} 
                        name="email"
                        value={email}
                        onChange={handleEmail}
                        placeholder="email"/>

                <button className="bgBlue text-white uppercase text-3xl rounded-full px-12 py-16 right mx-auto mb-32 contact-bt"
                        onClick={sendEmail}>INVIA</button>
            </div>
            <div className="spazio"></div>
        </div>
    )
}

export default Contact;