import React from 'react'


function Service({title, text, icon}) {
    return (

        <div className="grid grid-cols-10 gap-0 md:gap-8 mb-12 md:mb-0">
            <div className="col-span-10 md:col-span-1 text-6xl textBlue text-center">
                <i className={icon}></i>
            </div>
            <div className="col-span-10 md:col-span-9 text-center md:text-left">
                <p className="uppercase textGrey text-xl">
                    {title}
                </p>
                <p dangerouslySetInnerHTML={{__html: text }} className="textGrey text-base"></p>
            </div>
        </div>


    )
}


export default Service;