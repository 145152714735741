import React from 'react'

function Description({text, icons}) {

    const iconList = (
        <div className="grid md:grid-flow-col grid-cols-7 gap-4 py-12">
        {icons.map((item, index) => (
            <div className="col-span-7 md:col-span-1 text-center text-6xl textGrey" key={index}>
                <i className={item}></i>
            </div>
        ))}

        </div>
    )


    return (
        <div>
            <p dangerouslySetInnerHTML={{__html: text }}
                className="uppercase textGrey text-xl text-center"></p>
            
            {iconList}
        </div>
    )

}

export default Description;